import { Alert, Button, Input } from '@electro/shared-ui-components'
import {
  AdminBusinessBillingProvider,
  useAdminBusinessBilling,
} from '@electro/fleets/src/components/AdminBusinessBillingForm/hooks'
import {
  AdminBusinessBIllingFormFields,
  AdminBusinessBillingFormFieldsEnum,
} from '@electro/fleets/src/components/AdminBusinessBillingForm/AdminBusinessBillingForm.types'
import { ReactNode } from 'react'

const {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  MOBILE,
  BILLING_ADDRESS_1,
  BILLING_ADDRESS_2,
  BILLING_POSTCODE,
} = AdminBusinessBillingFormFieldsEnum

interface Props {
  onSuccess?: () => void
  formFields?: Partial<AdminBusinessBIllingFormFields>
  children: ReactNode | ReactNode[]
}

/**
 * @deprecated This component is not used as billing address is not editable in the app
 */
const AdminBusinessBillingForm = ({ onSuccess, formFields, children }: Props) => (
  <AdminBusinessBillingProvider onSuccess={onSuccess} formFields={formFields}>
    {children}
  </AdminBusinessBillingProvider>
)

const Fields = () => {
  const { formik, error } = useAdminBusinessBilling()

  return (
    <form
      data-testid="business-billing-form"
      id="business-billing-form"
      onSubmit={formik.handleSubmit}
    >
      <h3>Billing Address</h3>

      {!!error && (
        <Alert className="mb-4" variant="error">
          {error.message}
        </Alert>
      )}

      <Input
        name={EMAIL}
        fullWidth
        label="Email address"
        placeholder="E.g. jane@smith.com"
        value={formik.values[EMAIL]}
        errorMessage={formik.errors[EMAIL]}
        onChange={formik.handleChange}
      />

      <Input
        name={FIRST_NAME}
        fullWidth
        label="Cardholder first name"
        placeholder="E.g. Jane"
        value={formik.values[FIRST_NAME]}
        errorMessage={formik.errors[FIRST_NAME]}
        onChange={formik.handleChange}
      />

      <Input
        name={LAST_NAME}
        fullWidth
        label="Cardholder last name"
        placeholder="E.g. Smith"
        value={formik.values[LAST_NAME]}
        errorMessage={formik.errors[LAST_NAME]}
        onChange={formik.handleChange}
      />

      <Input
        name={MOBILE}
        fullWidth
        label="Mobile phone number"
        placeholder="E.g. 07123456789"
        value={formik.values[MOBILE]}
        errorMessage={formik.errors[MOBILE]}
        onChange={formik.handleChange}
      />

      <Input
        name={BILLING_ADDRESS_1}
        fullWidth
        label="Billing address line 1"
        placeholder="E.g. 11 High Street"
        value={formik.values[BILLING_ADDRESS_1]}
        errorMessage={formik.errors[BILLING_ADDRESS_1]}
        onChange={formik.handleChange}
      />

      <Input
        fullWidth
        name={BILLING_ADDRESS_2}
        label="Billing address line 2"
        placeholder="E.g. London"
        value={formik.values[BILLING_ADDRESS_2]}
        errorMessage={formik.errors[BILLING_ADDRESS_2]}
        onChange={formik.handleChange}
      />

      <Input
        fullWidth
        name={BILLING_POSTCODE}
        label="Billing postcode"
        placeholder="E.g. PO57 6DE"
        value={formik.values[BILLING_POSTCODE]}
        errorMessage={formik.errors[BILLING_POSTCODE]}
        onChange={formik.handleChange}
      />
    </form>
  )
}

const SubmitButton = () => {
  const { loading } = useAdminBusinessBilling()

  return (
    <Button fullWidth form="business-billing-form" type="submit" disabled={loading}>
      {loading ? 'Saving...' : 'Save details'}
    </Button>
  )
}

AdminBusinessBillingForm.Fields = Fields
AdminBusinessBillingForm.SubmitButton = SubmitButton

export { AdminBusinessBillingForm }
