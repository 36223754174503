import { useEffect, useState } from 'react'

import {
  useFleetsDriversTokensQuery,
  EjnEjnTokenStatusChoices,
  useFleetsCardOrdersQuery,
} from '@electro/fleets/generated/graphql'

import {
  AllElectroCardsAssignedModal,
  AssignElectrocardModal,
  OrderMoreElectroCardsModal,
} from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/components/AssignElectroCardModalScreen/components'
import { LoadingOverlay } from '@electro/shared-ui-components'

export const AssignElectroCardModalScreen = () => {
  const { data, loading } = useFleetsDriversTokensQuery({
    variables: {
      first: 50,
      offset: 0,
      status: EjnEjnTokenStatusChoices.Unassigned,
    },
  })
  const { data: cardOrderData, loading: cardOrderLoading } = useFleetsCardOrdersQuery()
  const [hasOrderedElectroCards, setHasOrderedElectroCards] = useState<boolean>()
  const [hasUnassignedCards, setHasUnassignedCards] = useState<boolean>()

  useEffect(() => {
    setHasUnassignedCards(data?.unassignedTokens?.totalCount > 0)
    setHasOrderedElectroCards(cardOrderData?.fleetsCardOrders?.edges?.length > 0)
  }, [cardOrderData, data])

  return loading || cardOrderLoading ? (
    <div className="h-112">
      <LoadingOverlay fixed />
    </div>
  ) : (
    <>
      <AssignElectrocardModal show={hasOrderedElectroCards && hasUnassignedCards} />
      <OrderMoreElectroCardsModal show={!hasOrderedElectroCards && hasUnassignedCards} />
      <AllElectroCardsAssignedModal show={!hasUnassignedCards && hasOrderedElectroCards} />
    </>
  )
}
