import { useRouter } from 'next/router'

import {
  EditDriverFormFields,
  DriverFormFieldNames,
  keysOfEditDriverFormFields,
} from '@electro/fleets/src/types/DriverForm'
import { EditDriverForm } from '@electro/fleets/src/components'
import { useErrorNotificationEffect } from '@electro/shared/hooks'
import {
  useFleetsEditDriver,
  useFleetsFetchDriver,
  FetchDriver,
} from '@electro/fleets/src/services'
import { convertDriverFormToMutationArgs } from '@electro/fleets/src/utils/convertDriverFormToMutationArgs'
import FLEETS_DRIVERS_QUERY from '@electro/fleets/graphql/fleetsDriversQuery.graphql'
import FLEETS_DRIVER_QUERY from '@electro/fleets/graphql/fleetsDriverQuery.graphql'
import { useAsPath, useDriversParams } from '@electro/fleets/src/hooks/stores'
import { clearFleetsDriversFromCache } from '@electro/fleets/src/utils/apolloUtils'

const { GIVEN_NAME, FAMILY_NAME, MOBILE, EMAIL, TAGS } = DriverFormFieldNames

function deriveEditDriverFormFieldsFrom(data: FetchDriver): EditDriverFormFields {
  if (!data) return null
  return {
    [GIVEN_NAME]: data.fleetsDriver.user.firstName,
    [FAMILY_NAME]: data.fleetsDriver.user.lastName,
    [MOBILE]: data.fleetsDriver.mobileNumber,
    [EMAIL]: data.fleetsDriver.user.email,
    [TAGS]: data.fleetsDriver.tags,
  }
}

interface GetChangeRequestDiffArgs {
  oldValues: EditDriverFormFields
  nextValues: EditDriverFormFields
}

function getChangeRequestDiff({
  oldValues,
  nextValues,
}: GetChangeRequestDiffArgs): Partial<EditDriverFormFields> {
  const changeRequest = {}
  Object.keys(nextValues).forEach((key: `${keysOfEditDriverFormFields}`) => {
    if (oldValues[key] !== nextValues[key]) {
      changeRequest[key] = nextValues[key]
    }
  })
  return changeRequest
}

export const EditDriverFormContainer = ({ driverPk }: { driverPk: string }) => {
  const router = useRouter()
  const { driversQueryParams } = useDriversParams()
  const { prevAsPath } = useAsPath()
  const [fleetsEditDriverMutation, fleetsEditDriver] = useFleetsEditDriver()
  const fleetsDriverQuery = useFleetsFetchDriver({
    variables: { pk: parseInt(driverPk, 10) },
  })

  const fleetsEditDriverErrorNotification = useErrorNotificationEffect({
    error: fleetsEditDriver.error,
    message: 'Could not submit your changes!',
  })

  useErrorNotificationEffect({
    error: fleetsDriverQuery.error,
    message: 'Could not get driver data, please try again later!',
  })

  async function handleSubmit(nextValues: EditDriverFormFields) {
    const oldValues = deriveEditDriverFormFieldsFrom(fleetsDriverQuery.data)
    const changeRequest = getChangeRequestDiff({ oldValues, nextValues })
    const mutationArgs = convertDriverFormToMutationArgs(changeRequest)

    try {
      await fleetsEditDriverMutation({
        refetchQueries: [
          {
            query: FLEETS_DRIVERS_QUERY,
            variables: driversQueryParams,
          },
          {
            query: FLEETS_DRIVER_QUERY,
            variables: {
              pk: parseInt(driverPk, 10),
            },
          },
        ],
        variables: {
          pk: parseInt(driverPk, 10),
          ...mutationArgs,
        },
        awaitRefetchQueries: true,
        update: (cache, { data: updateDriverData }) => {
          if (updateDriverData.fleetsEditDriver.success) {
            clearFleetsDriversFromCache(cache)
          }
        },
      })
      if (prevAsPath) {
        router.push(prevAsPath)
        return
      }
      router.push(`/dashboard/drivers`)
    } catch {
      fleetsEditDriverErrorNotification.errorNotification()
    }
  }

  return fleetsDriverQuery.data ? (
    <EditDriverForm
      onSubmit={handleSubmit}
      sending={fleetsEditDriver.loading}
      loading={fleetsDriverQuery.loading}
      error={fleetsDriverQuery.error || fleetsEditDriver.error}
      formFields={deriveEditDriverFormFieldsFrom(fleetsDriverQuery.data)}
      disabledFields={[
        DriverFormFieldNames.GIVEN_NAME,
        DriverFormFieldNames.FAMILY_NAME,
        DriverFormFieldNames.EMAIL,
      ]}
    />
  ) : null
}
