import {
  VehicleRegLookup,
  REGISTRATION_LOOKUP_FORM_ID,
  VehicleRegLookupFields,
  VehicleOptionsSelect,
} from '@electro/shared/components'
import { Button, Typography, useToastNotification } from '@electro/shared-ui-components'
import Image from 'next/image'
import { useState } from 'react'
import { VehicleType, useExtendedVehicleLookupLazyQuery } from '@electro/fleets/generated/graphql'
import {
  AddEvFormStage,
  useAddEvForm,
} from '@electro/fleets/src/components/VehicleList/components/AddEvForm/hooks'
import getRandomItemFromArray from '@electro/shared/utils/getRandomItemFromArray'
import { iceErrors } from '@electro/shared/constants'
import { GTM } from '@electro/fleets/src/utils/event-triggers'
import { useBusinessEntityStore } from '@electro/fleets/src/hooks/stores'
import useTranslation from 'next-translate/useTranslation'

enum RegVehicleLookupStage {
  REGISTRATION_LOOKUP = 'REGISTRATION_LOOKUP',
  SELECT_VEHICLE = 'SELECT_VEHICLE',
}

export const RegistrationVehicleLookup = () => {
  const { t } = useTranslation('common')
  const [regVehicleLookupStage, setRegVehicleLookupStage] = useState<RegVehicleLookupStage>(
    RegVehicleLookupStage.REGISTRATION_LOOKUP,
  )
  const [regNumber, setRegNumber] = useState<string>('')
  const [isElectric, setIsElectric] = useState<boolean>(true)
  const [vehicleOptions, setVehicleOptions] = useState(null)
  const [selectedVehicle, setSelectedVehicle] = useState<VehicleType>(null)

  const companyName = useBusinessEntityStore((state) => state.businessEntity.companyName)

  const [getVehicleLookup, { data, loading, error }] = useExtendedVehicleLookupLazyQuery()
  const { showToastNotification } = useToastNotification()
  const { setFormState, handleSubmit, loading: submitLoading } = useAddEvForm()

  const handleLookupSubmit = async ({ regNumber: lookupRegNumber }: VehicleRegLookupFields) => {
    try {
      setRegNumber(lookupRegNumber)
      const response = await getVehicleLookup({
        variables: { regNumber: lookupRegNumber, useHeuristics: true },
      })
      setIsElectric(response.data.extendedVehicleLookup.isElectric)
      setVehicleOptions(response.data.extendedVehicleLookup.options)

      if (response.data.extendedVehicleLookup.isElectric) {
        setRegVehicleLookupStage(RegVehicleLookupStage.SELECT_VEHICLE)
      }
    } catch (err) {
      showToastNotification({
        heading: 'Oops!',
        body: err.message,
        variant: 'error',
      })
    }
  }

  const handleSelectVehicle = (value: VehicleType) => {
    setSelectedVehicle(value)
  }

  if (regVehicleLookupStage === RegVehicleLookupStage.SELECT_VEHICLE) {
    return (
      <div className="flex flex-col">
        <Typography variant="h1" className="mb-4">
          {data?.extendedVehicleLookup.make}
        </Typography>
        <Typography>{t('vehicles.add_vehicle_modal.select_model')}</Typography>

        <div className="overflow-auto mt-7 max-h-[calc(100vh-300px)]">
          <VehicleOptionsSelect options={vehicleOptions} onSelect={handleSelectVehicle} />
        </div>

        <div className="mt-4 px-12 space-y-4">
          <Button
            fullWidth
            disabled={!selectedVehicle || submitLoading}
            onClick={() => {
              handleSubmit(selectedVehicle, regNumber)
              GTM.submitVehicle({
                companyName,
                vehicle: selectedVehicle,
                vehicleLookup: 'registration',
              })
            }}
          >
            {submitLoading
              ? t('vehicles.add_vehicle_modal.button.adding_vehicle')
              : t('vehicles.add_vehicle_modal.button.add_vehicle')}
          </Button>

          <Button
            fullWidth
            variant="outline"
            onClick={() => setRegVehicleLookupStage(RegVehicleLookupStage.REGISTRATION_LOOKUP)}
          >
            {t('common.back')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="text-center">
        <Image
          className=" m-auto"
          src="/images/zap-car.svg"
          alt="Electroverse Logo"
          width="210"
          height="180"
        />

        <Typography className="my-8">
          {t('vehicles.add_vehicle_modal.enter_vehicle_instruction')}
        </Typography>
      </div>

      <VehicleRegLookup
        isElectric={isElectric}
        loading={loading}
        error={error}
        onSubmit={handleLookupSubmit}
        initialRegNumber={regNumber}
      />

      {!isElectric && (
        <div className="text-action-danger text-center">
          <div className="font-medium">{getRandomItemFromArray(iceErrors)}</div>
          That doesn&apos;t look like an EV!
        </div>
      )}

      <div className="mt-4 px-12 space-y-4">
        <Button
          form={REGISTRATION_LOOKUP_FORM_ID}
          disabled={loading}
          type="submit"
          variant="default"
          fullWidth
        >
          {loading ? t('common.button.loading_searching') : t('common.button.search')}
        </Button>

        <Button
          fullWidth
          variant="outline"
          onClick={() => setFormState(AddEvFormStage.MANUAL_LOOK_UP)}
        >
          {t('vehicles.add_vehicle_modal.button.add_manually')}
        </Button>
      </div>
    </div>
  )
}
