import {
  DriverTypeOrderingFields,
  FleetsDriversQueryVariables,
  OrderingDirectionEnum,
} from '@electro/fleets/generated/graphql'
import {
  FIRST_PARAM,
  IS_ACTIVE_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
  ORDER_DIRECTION_PARAM,
  SEARCH,
} from '@electro/fleets/src/constants/urlParams'

import { useRouter } from 'next/router'
import { useMount } from 'react-use'
import { create } from 'zustand'

interface QueryParams {
  driversQuery: FleetsDriversQueryVariables
}

interface UseDriversParams {
  driversQueryParams: FleetsDriversQueryVariables
  syncDriversQueryParams: (params: Partial<FleetsDriversQueryVariables>) => void
  resetDriversQueryParams: () => void
}

interface State {
  queryParams: QueryParams
  setQueryParams: (params: Partial<QueryParams>) => void
}

export const INITIAL_STATE: QueryParams = {
  driversQuery: {
    [FIRST_PARAM]: 50,
    [OFFSET_PARAM]: 0,
    ordering: [
      {
        [ORDER_DIRECTION_PARAM]: OrderingDirectionEnum.Asc,
        [ORDER_BY_PARAM]: DriverTypeOrderingFields.Name,
      },
    ],
    [SEARCH]: '',
    [IS_ACTIVE_PARAM]: true,
  },
}

/**
 * The store that contains state for all the query params in the URL
 */
const useUrlParamsStore = create<State>((set) => ({
  queryParams: INITIAL_STATE,
  setQueryParams: (params: Partial<QueryParams>) => {
    set((state) => ({
      queryParams: {
        ...state.queryParams,
        ...params,
      },
    }))
  },
}))

/**
 * Initialises only the drivers query params from the URL on page load.
 * Use this hook on pages where you only need to sync the drivers query params.
 * If the url params are not present when the page mounts this will apply default settings
 * to both the Url and store.
 */
export const useDriversQueryParamsInitialiser = () => {
  const setQueryParams = useUrlParamsStore((state) => state.setQueryParams)
  const { syncDriversQueryParams, driversQueryParams } = useDriversParams()

  useMount(() => {
    const urlParams = new URLSearchParams(window.location.search)
    /**
     * When is active is set to false in the URL we want to send 'null' to BE
     * true = only active drivers
     * false = only inactive drivers
     * null = all drivers
     */
    const getIsActiveValue = () => {
      if (urlParams.get(IS_ACTIVE_PARAM) === 'true') {
        return true
      }
      if (urlParams.get(IS_ACTIVE_PARAM) === 'false') {
        return null
      }
      return driversQueryParams?.[IS_ACTIVE_PARAM]
    }

    const driversQuery = {
      [FIRST_PARAM]: parseInt(urlParams.get(FIRST_PARAM) ?? '50', 10) ?? driversQueryParams?.first,
      [OFFSET_PARAM]:
        parseInt(urlParams.get(OFFSET_PARAM) ?? '0', 10) ?? driversQueryParams?.[OFFSET_PARAM],
      [SEARCH]: urlParams.get(SEARCH) ?? driversQueryParams?.[SEARCH] ?? '',
      /**
       * We need to handle the isActive param differently because it's a boolean
       * and we need to check if the param is present as a string in the URL or not.
       * If it's not present, we set it to null so that we show both active
       * and inactive drivers. Setting it to false will only return inactive drivers.
       */
      [IS_ACTIVE_PARAM]: getIsActiveValue(),

      /**
       * The shape of the ordering param is as it is sent to the Query here to make it easier
       * to send to the server later.
       */
      ordering: [
        {
          [ORDER_DIRECTION_PARAM]:
            (urlParams.get(ORDER_DIRECTION_PARAM) as OrderingDirectionEnum) ||
            OrderingDirectionEnum.Asc,
          [ORDER_BY_PARAM]:
            driversQueryParams.ordering[0]?.[ORDER_BY_PARAM] ??
            (urlParams.get(ORDER_BY_PARAM) as DriverTypeOrderingFields),
        },
      ],
    }

    syncDriversQueryParams(driversQuery)

    setQueryParams({
      driversQuery,
    })
  })
}

/**
 * Syncs the URL params with the store on initial page load and router changes
 * use this hook on pages where you need to sync all the query params in the URL
 */
export const useUrlParamInitialiser = () => {
  useDriversQueryParamsInitialiser()
}

/**
 * Exports the slice of state that contains the drivers query params
 */
export const useDriversParams = (): UseDriversParams => {
  const router = useRouter()
  const driversQueryParams = useUrlParamsStore((state) => state.queryParams.driversQuery)
  const setQueryParams = useUrlParamsStore((state) => state.setQueryParams)
  const currentOrderingFromStore = driversQueryParams.ordering[0]

  const syncDriversQueryParams = (newParams: Partial<FleetsDriversQueryVariables>) => {
    setQueryParams({
      driversQuery: {
        ...driversQueryParams,
        ...newParams,
        ...(newParams[IS_ACTIVE_PARAM] ? { [IS_ACTIVE_PARAM]: true } : { [IS_ACTIVE_PARAM]: null }),
      },
    })

    router.replace({
      /**
       * We've flattened the query params here to make it easier to set to the URL
       * DataGrid also currently expects these params in this format
       */
      query: {
        ...router.query,
        [FIRST_PARAM]:
          newParams[FIRST_PARAM]?.toString() ?? driversQueryParams[FIRST_PARAM].toString(),
        [OFFSET_PARAM]:
          newParams[OFFSET_PARAM]?.toString() ?? driversQueryParams[OFFSET_PARAM].toString(),
        [SEARCH]: newParams[SEARCH] ?? driversQueryParams[SEARCH],
        [ORDER_DIRECTION_PARAM]:
          newParams.ordering?.[0]?.[ORDER_DIRECTION_PARAM] ??
          currentOrderingFromStore[ORDER_DIRECTION_PARAM],
        [ORDER_BY_PARAM]:
          newParams.ordering?.[0]?.[ORDER_BY_PARAM] ?? currentOrderingFromStore[ORDER_BY_PARAM],
        ...(newParams[IS_ACTIVE_PARAM] || !driversQueryParams[IS_ACTIVE_PARAM]
          ? { [IS_ACTIVE_PARAM]: 'true' }
          : { [IS_ACTIVE_PARAM]: 'false' }),
      },
    })
  }

  const resetDriversQueryParams = () => {
    setQueryParams({
      driversQuery: INITIAL_STATE.driversQuery,
    })
  }

  return { driversQueryParams, syncDriversQueryParams, resetDriversQueryParams }
}
