import { useState, useEffect, useContext, createContext, useCallback } from 'react'
import { client } from '@electro/fleets/client'
import { useRouter } from 'next/router'
import {
  useFetchFleetsAdminLoginToken,
  useFleetsFetchAdminUser,
  useFleetsFetchBusinessEntity,
  useFleetsFetchRefreshToken,
  useSsoLogin,
} from '@electro/fleets/src/services'
import {
  AdminUserTypesEnum,
  useAdminUserStore,
  useAuthStore,
  useBusinessEntityStore,
} from '@electro/fleets/src/hooks/stores'
import { useMutation } from '@apollo/client'

import { GTM } from '@electro/fleets/src/utils/event-triggers'
import { useLocalStorage, useUnmount } from 'react-use'
import { FLEETS_ACCOUNT_ID } from '@electro/fleets/src/constants/localStorage'
import {
  AccountType,
  useFleetsCurrentAdminAccountsLazyQuery,
  useVerifyTokenMutation,
} from '@electro/fleets/generated/graphql'
import { setActiveAccountToLocalStorage } from '@electro/fleets/src/utils/asyncLocalStorage'
import { useFleetsLogoutMutation } from '@electro/fleets/src/services/useFleetsLogoutMutation'
import { GraphQLError } from 'graphql'
import { SANDBOX_SPIN_UP_DEMO_MUTATION } from '@electro/fleets/graphql/sandbox'
import { CountryCodeType } from '@electro/fleets/src/constants/countryCodes'

type Email = string

interface LoginCredentials {
  email: Email
  password: string
}

export interface UseFleetsAuth {
  login: (User) => void
  logout: () => void
  demoLogin: ({ demoRequestToken }: { demoRequestToken: string }) => void
  loginWithSso: ({ userId, ssoToken }: { userId: number; ssoToken: string }) => void
  handleAccountSelect: (account: AccountType) => void
  /**
   * @isAuthenticated
   * flag that is set to true when the user is fully authenticated
   * and has a valid token.
   */
  isAuthenticated: boolean
  /**
   * @hasAuthenticationCheckBeenMade
   * flag that is set to true when an auth check has been attempted at least once.
   */
  hasAuthenticationCheckBeenMade: boolean
  isAuthenticatedLoading: boolean
  isAuthenticatedError: GraphQLError
  isLoginLoading: boolean
  logoutLoading: boolean
}

const FleetsAuthContext = createContext(null)

export const useFleetsAuth = (): UseFleetsAuth => useContext(FleetsAuthContext)

export function useProvideFleetsAuth(): UseFleetsAuth {
  const [isLoginLoading, setIsLoginLoading] = useState(false)
  const [companyName, setCompanyName] = useState<string>()

  const router = useRouter()

  const [ssoLogin] = useSsoLogin()
  const [fetchFleetsAccessToken] = useFetchFleetsAdminLoginToken()
  const [refreshTokenMutation] = useFleetsFetchRefreshToken()
  const [fetchCurrentAdminUser] = useFleetsFetchAdminUser()
  const [fetchBusinessEntity] = useFleetsFetchBusinessEntity()
  const [getAdminAccounts] = useFleetsCurrentAdminAccountsLazyQuery()
  const [verifyToken] = useVerifyTokenMutation()
  const [fleetsLogout, { loading: logoutLoading }] = useFleetsLogoutMutation()
  const [spinUpDemo] = useMutation(SANDBOX_SPIN_UP_DEMO_MUTATION)

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)
  const setIsAuthenticated = useAuthStore((state) => state.setIsAuthenticated)
  const isAuthenticatedLoading = useAuthStore((state) => state.isAuthenticatedLoading)
  const setIsAuthenticatedLoading = useAuthStore((state) => state.setIsAuthenticatedLoading)
  const hasAuthenticationCheckBeenMade = useAuthStore(
    (state) => state.hasAuthenticationCheckBeenMade,
  )
  const setHasAuthenticationCheckBeenMade = useAuthStore(
    (state) => state.setHasAuthenticationCheckBeenMade,
  )
  const isAuthenticatedError = useAuthStore((state) => state.isAuthenticatedError)
  const setIsAuthenticatedError = useAuthStore((state) => state.setIsAuthenticatedError)

  const setCurrentAdminUserStore = useAdminUserStore((state) => state.setCurrentAdminUser)
  const resetCurrentAdminUserStore = useAdminUserStore((state) => state.resetCurrentAdminUser)
  const updateBusinessEntityStore = useBusinessEntityStore((state) => state.updateBusinessEntity)
  const resetBusinessEntityStore = useBusinessEntityStore((state) => state.resetBusinessEntity)

  const [activeAccount, , removeActiveAccount] = useLocalStorage(FLEETS_ACCOUNT_ID, null)

  const logout = useCallback(async () => {
    GTM.loggedOut({ companyName })
    await fleetsLogout()
    setIsAuthenticated(false)
    setHasAuthenticationCheckBeenMade(true)
    setIsAuthenticatedLoading(false)
    resetCurrentAdminUserStore()
    resetBusinessEntityStore()
    removeActiveAccount()
    // https://www.apollographql.com/docs/react/caching/advanced-topics/#resetting-the-cache
    await client.clearStore()
    router.push('/dashboard/login')
  }, [
    resetBusinessEntityStore,
    resetCurrentAdminUserStore,
    router,
    companyName,
    removeActiveAccount,
    fleetsLogout,
    setIsAuthenticated,
    setHasAuthenticationCheckBeenMade,
    setIsAuthenticatedLoading,
  ])

  /**
   * This login is specific to the fleets demo flow.
   * It will spin up a demo fleet from a seed and log the
   * user in. This lives here because it relates to the auth flow.
   */
  const demoLogin = async ({ demoRequestToken }: { demoRequestToken: string }) => {
    setIsAuthenticatedLoading(true)
    try {
      setIsLoginLoading(true)
      setIsAuthenticatedError(null)

      await spinUpDemo({ variables: { demoRequestToken } })
      await postLogin()

      setIsAuthenticated(true)
      setHasAuthenticationCheckBeenMade(true)
      GTM.loggedIn({ companyName })
    } catch (err) {
      setIsAuthenticatedError(err)
    } finally {
      setIsLoginLoading(false)
      setIsAuthenticatedLoading(false)
    }
  }

  const setCurrentAdminUserToGlobalStore = useCallback(async () => {
    try {
      const { data } = await fetchCurrentAdminUser()

      setCurrentAdminUserStore({
        email: data.fleetsCurrentAdmin.user.email,
        userType: data.fleetsCurrentAdmin.isSuperuser
          ? AdminUserTypesEnum.SUPER_ADMIN
          : AdminUserTypesEnum.ADMIN,
        hasSignedUp: data.fleetsCurrentAdmin.hasSignedUp,
      })
    } catch (err) {
      setIsAuthenticatedError(err)
    }
  }, [fetchCurrentAdminUser, setCurrentAdminUserStore, setIsAuthenticatedError])

  const setBusinessEntityToGlobalStore = useCallback(async () => {
    try {
      const { data } = await fetchBusinessEntity()
      setCompanyName(data.fleetsBusinessEntity.name)

      const hasDeliveryAddress = !!(
        data.fleetsBusinessEntity.deliveryAddressLine1 !== '' &&
        data.fleetsBusinessEntity.deliveryPostcode
      )
      const hasPaymentMethod = !!data.fleetsBusinessEntity.billingAccount
      const {
        countryCode,

        hasCharged,
        referralCode,
        registeredAt,
        isVerified: hasVerifiedEmail,
        logo,
        companyHouseNumber,
        companyType,
        charityNumber,
        vatNumber,
      } = data.fleetsBusinessEntity
      updateBusinessEntityStore({
        companyName,
        hasCharged,
        countryCode: countryCode as CountryCodeType,
        referralCode,
        registeredAt,
        logo,
        companyHouseNumber,
        charityNumber,
        companyType,
        vatNumber,
        onboardingFlags: {
          hasDeliveryAddress,
          hasPaymentMethod,
          hasVerifiedEmail,
        },
      })
    } catch (err) {
      setIsAuthenticatedError(err)
    }
  }, [fetchBusinessEntity, updateBusinessEntityStore, companyName, setIsAuthenticatedError])

  const postLogin = useCallback(async () => {
    try {
      await setBusinessEntityToGlobalStore()
      await setCurrentAdminUserToGlobalStore()
    } catch (err) {
      setIsAuthenticatedError(err)
    }
  }, [setBusinessEntityToGlobalStore, setCurrentAdminUserToGlobalStore, setIsAuthenticatedError])

  const login = async ({ email, password }: LoginCredentials) => {
    try {
      setIsLoginLoading(true)
      setIsAuthenticatedError(null)

      await fetchFleetsAccessToken({ variables: { email, password } })
      await postLogin()

      setIsAuthenticated(true)
      setHasAuthenticationCheckBeenMade(true)
      GTM.loggedIn({ companyName })
    } catch (err) {
      setIsAuthenticatedError(err)
      logout()
    } finally {
      setIsLoginLoading(false)
    }
  }

  /**
   * @loginWithSso
   * Will authenticate via the `quickAuth` endpoint.
   * Doing so allows us to auth a user with a single use token.
   * Used during the sign up process to log users in after a verification email
   */
  const loginWithSso = useCallback(
    async ({ userId, ssoToken }) => {
      setIsAuthenticatedLoading(true)
      try {
        await ssoLogin({
          variables: {
            user: userId,
            shortLivedToken: ssoToken,
          },
        })
        await postLogin()
        setIsAuthenticated(true)
        setHasAuthenticationCheckBeenMade(true)
      } catch (err) {
        setIsAuthenticatedError(err)
      } finally {
        setIsAuthenticatedLoading(false)
      }
    },
    [
      ssoLogin,
      setIsAuthenticatedError,
      setIsAuthenticatedLoading,
      postLogin,
      setIsAuthenticated,
      setHasAuthenticationCheckBeenMade,
    ],
  )

  /**
   * refreshAccessToken()
   * Utility to refresh the users session.
   * This is useful for when we need to dynamically update user data in the session.
   * For example updating flags like `hasValidPaymentInstruction` after the user has
   * added a payment method.
   */
  const refreshAccessToken = useCallback(async () => {
    try {
      await refreshTokenMutation()
      await postLogin()

      setIsAuthenticated(true)
      setHasAuthenticationCheckBeenMade(true)
    } catch (err) {
      /**
       * If we get an error here we're going to assume it's either an
       * expired/revoked refresh token or someone messing around
       * with tokens. Either way They're getting quietly logged out!
       */
      logout()
    }
    return null
  }, [
    logout,
    refreshTokenMutation,
    setIsAuthenticated,
    setHasAuthenticationCheckBeenMade,
    postLogin,
  ])

  const handleAccountSelect = useCallback(
    async (account: AccountType) => {
      await setActiveAccountToLocalStorage(account.id?.toString())
      await postLogin()
    },
    [postLogin],
  )

  /**
   * Watch localStorage if selected account id is set up. If
   * there is not an selected account id on localStorage, we
   * will redirect user to select one on account select page
   * or set the account id automatically
   */
  useEffect(() => {
    if (!isAuthenticated) return

    const checkAccountSelect = async () => {
      const { data } = await getAdminAccounts()

      // if admin have multiple accounts redirect user to
      // account select page to chose one of their accounts
      if (!activeAccount && data?.fleetsCurrentAdminAccounts?.length > 1) {
        router.push('/dashboard/account-select')
      }

      // if admin has only 1 account, set that account id on localStorage automatically
      if (!activeAccount && data?.fleetsCurrentAdminAccounts?.length === 1) {
        const firstAccount = data?.fleetsCurrentAdminAccounts?.[0]
        handleAccountSelect(firstAccount)
      }
    }

    checkAccountSelect()
    // eslint-disable-next-line
  }, [activeAccount, getAdminAccounts, isAuthenticated])

  /**
   * Trigger verify token when user reload the page
   *
   * @TODO
   * We need to have a global watcher which will
   * watch requests and on first 403 request will force
   * verify token process
   */
  useEffect(() => {
    const pathnameRequiresVerification = router.pathname.includes('/dashboard')

    const triggerVerifyToken = async () => {
      setIsAuthenticatedLoading(true)

      try {
        await verifyToken()
        await postLogin()

        setIsAuthenticated(true)
        setHasAuthenticationCheckBeenMade(true)
      } catch (err) {
        refreshAccessToken()
      } finally {
        setIsAuthenticatedLoading(false)
      }
    }

    if (!isAuthenticated && !hasAuthenticationCheckBeenMade && pathnameRequiresVerification) {
      triggerVerifyToken()
    }
  }, [
    isAuthenticated,
    hasAuthenticationCheckBeenMade,
    verifyToken,
    refreshAccessToken,
    setIsAuthenticated,
    setIsAuthenticatedLoading,
    setHasAuthenticationCheckBeenMade,
    postLogin,
    router.pathname,
  ])

  useUnmount(() => setIsAuthenticatedLoading(false))

  return {
    loginWithSso,
    login,
    demoLogin,
    logout,
    handleAccountSelect,
    isAuthenticated,
    hasAuthenticationCheckBeenMade,
    isAuthenticatedLoading,
    isLoginLoading,
    isAuthenticatedError,
    logoutLoading,
  }
}

export const FleetsAuthProvider = ({ children }) => {
  const auth = useProvideFleetsAuth()
  return <FleetsAuthContext.Provider value={auth}>{children}</FleetsAuthContext.Provider>
}
