import { Typography } from '@electro/server-components'
import { SwitchToggle } from '@electro/shared-ui-components'
import { useDriversParams } from '@electro/fleets/src/hooks/stores'
import useTranslation from 'next-translate/useTranslation'

export const FilterToolbar = ({ getDrivers }) => {
  const { t } = useTranslation('common')
  const { driversQueryParams, syncDriversQueryParams } = useDriversParams()

  const { isActive } = driversQueryParams

  const handleToggleActiveDrivers = () => {
    const nextIsActive = isActive ? null : true
    syncDriversQueryParams({ isActive: nextIsActive })
    getDrivers({
      variables: {
        ...driversQueryParams,
        isActive: nextIsActive,
      },
    })
  }

  return (
    <>
      <Typography variant="small">{t('drivers.filter_toolbar.active_only')}</Typography>
      <SwitchToggle checked={!!isActive} onChange={handleToggleActiveDrivers} />
    </>
  )
}
