export interface AdminBusinessBIllingFormFields {
  email: string
  firstName: string
  lastName: string
  mobile: string
  billingAddressLine1: string
  billingAddressLine2: string
  billingPostcode: string
  billingCountryCode: string
}

export enum AdminBusinessBillingFormFieldsEnum {
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  MOBILE = 'mobile',
  BILLING_ADDRESS_1 = 'billingAddressLine1',
  BILLING_ADDRESS_2 = 'billingAddressLine2',
  BILLING_POSTCODE = 'billingPostcode',
  BILLING_COUNTRY_CODE = 'billingCountryCode',
}
