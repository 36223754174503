import { useState, createContext, useContext, ReactNode } from 'react'

/**
 * Business types that can be selected by the user when signing up.
 * These map to specific validation rules on BE & FE. They are not
 * exposed by the schema so we are defining them here.
 */
export const BusinessType = {
  LIMITED_COMPANY: 'LIMITED',
  SOLE_TRADER: 'SOLE_TRADER',
  PARTNERSHIP: 'PARTNERSHIP',
  CHARITY: 'CHARITY',
}

export enum SignUpStep {
  SELECT_COMPANY_TYPE = 'selectCompanyType',
  BUSINESS_DETAILS_FORM = 'businessDetailsForm',
  BUSINESS_PROFILE_DATA_FORM = 'BusinessProfileDataForm',
  USER_DETAILS_FORM = 'userDetailsForm',
  COMPLETE = 'formCompleted',
  ERROR = 'error',
  LOADING = 'loading',
}

export enum SignUpFormFieldsEnum {
  BUSINESS_CLASSIFICATION = 'companyType',
  COMPANY_NAME = 'companyName',
  COMPANIES_HOUSE_NUMBER = 'companyHouseNumber',
  VAT_NUMBER = 'vatNumber',
  CHARITIES_COMMISSION_NUMBER = 'charityNumber',
  WEBSITE = 'website',
  BUSINESS_SECTOR = 'businessSector',
  HOW_DID_YOU_HEAR_ABOUT_US = 'howDidYouHearAboutUs',
  NUMBER_OF_ELECTRIC_VEHICLE_DRIVERS = 'numberOfElectricVehicleDrivers',
  PERCENTAGE_CHARGING_AT_HOME = 'percentageChargingAtHome',
  PERCENTAGE_CHARGING_AT_WORK = 'percentageChargingAtWork',
  PERCENTAGE_CHARGING_ON_PUBLIC_NETWORK = 'percentageChargingOnPublicNetwork',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PASSWORD = 'password',
}

export enum CompanyProfileDataFormFieldsEnum {
  BUSINESS_SECTOR = 'businessSector',
  HOW_DID_YOU_HEAR_ABOUT_US = 'howDidYouHearAboutUs',
  NUMBER_OF_ELECTRIC_VEHICLE_DRIVERS = 'numberOfElectricVehicleDrivers',
  PERCENTAGE_CHARGING_AT_HOME = 'percentageChargingAtHome',
  PERCENTAGE_CHARGING_AT_WORK = 'percentageChargingAtWork',
  PERCENTAGE_CHARGING_ON_PUBLIC_NETWORK = 'percentageChargingOnPublicNetwork',
}

export enum FormUserTypeEnum {
  /**
   * New users are classified as users who have not yet signed up to any Electroverse
   * platform and we do not have their email in the system.
   */
  NEW_USER = 'new',
  /**
   * Existing users are classified as users who have already signed up to Electroverse
   * and we already have their email in the system.
   */
  EXISTING_USER = 'existing',
}

export type FormUserType = `${FormUserTypeEnum}`

interface UseSignUpProvider {
  userType?: FormUserType
  onComplete?: (formData: SignUpFormFieldsType) => void
}

interface SignUpProviderProps extends UseSignUpProvider {
  children: ReactNode
}

export interface CompanyFormFields {
  [SignUpFormFieldsEnum.BUSINESS_CLASSIFICATION]: string
  [SignUpFormFieldsEnum.COMPANY_NAME]: string
  [SignUpFormFieldsEnum.COMPANIES_HOUSE_NUMBER]: string
  [SignUpFormFieldsEnum.VAT_NUMBER]?: string
  [SignUpFormFieldsEnum.CHARITIES_COMMISSION_NUMBER]?: string
  [SignUpFormFieldsEnum.WEBSITE]?: string
}

export interface CompanyProfileDataFormFields {
  [SignUpFormFieldsEnum.BUSINESS_SECTOR]: string
  [SignUpFormFieldsEnum.HOW_DID_YOU_HEAR_ABOUT_US]: string
  [SignUpFormFieldsEnum.NUMBER_OF_ELECTRIC_VEHICLE_DRIVERS]: number
  [SignUpFormFieldsEnum.PERCENTAGE_CHARGING_AT_HOME]: number
  [SignUpFormFieldsEnum.PERCENTAGE_CHARGING_AT_WORK]: number
  [SignUpFormFieldsEnum.PERCENTAGE_CHARGING_ON_PUBLIC_NETWORK]: number
}

export interface UserFormFields {
  [SignUpFormFieldsEnum.FIRST_NAME]: string
  [SignUpFormFieldsEnum.LAST_NAME]: string
  [SignUpFormFieldsEnum.PASSWORD]: string
}

export type SignUpFormFieldsType = CompanyFormFields | UserFormFields | CompanyProfileDataFormFields

interface State {
  formData: SignUpFormFieldsType
  activeScreen: SignUpStep
  currentStageIndex: number
  userType?: FormUserType
}

interface Handlers {
  handleScreenChange: (newScreen: SignUpStep) => () => void
  handleSignUpDataChange: (newData: SignUpFormFieldsType) => void
  handleFormCompleted: () => void
}

type SignUpContext = [State, Handlers]

export const STEPPER_FORM_STAGE_ORDER = [
  SignUpStep.SELECT_COMPANY_TYPE,
  SignUpStep.BUSINESS_DETAILS_FORM,
  SignUpStep.BUSINESS_PROFILE_DATA_FORM,
  SignUpStep.USER_DETAILS_FORM,
]

export const useSignUpProvider = ({ userType, onComplete }: UseSignUpProvider) => {
  const [activeScreen, setActiveScreen] = useState<SignUpStep>(SignUpStep.SELECT_COMPANY_TYPE)
  const [formData, setFormData] = useState<SignUpFormFieldsType>(null)

  const handleSignUpDataChange = (newData: SignUpFormFieldsType) => {
    setFormData((prevData) => ({ ...prevData, ...newData }))
  }

  const handleScreenChange = (newScreen: SignUpStep) => () => {
    setActiveScreen(newScreen)
  }

  const handleFormCompleted = () => {
    onComplete(formData)
    setFormData(null)
  }

  const currentStageIndex = STEPPER_FORM_STAGE_ORDER.indexOf(activeScreen)

  const state = {
    userType,
    currentStageIndex,
    formData,
    activeScreen,
  }

  const handlers = {
    handleScreenChange,
    handleSignUpDataChange,
    handleFormCompleted,
  }

  return [state, handlers]
}

const BusinessOnboardingContext = createContext<SignUpContext>(null)

export const useSignUp = () => {
  const context = useContext(BusinessOnboardingContext)
  if (!context) {
    throw new Error('useSignUp() must be used within a BusinessOnboardingProvider')
  }
  return context
}

export const BusinessOnboardingProvider = ({
  children,
  userType,
  onComplete,
}: SignUpProviderProps) => {
  const context = useSignUpProvider({ userType, onComplete })
  return (
    <BusinessOnboardingContext.Provider value={context}>
      {children}
    </BusinessOnboardingContext.Provider>
  )
}
