import React, { ChangeEvent, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Alert, Button, Modal, Typography } from '@electro/shared-ui-components'
import {
  DriverType,
  DriverTypeOrderingFields,
  EjnEjnTokenStatusChoices,
  FleetsDriversQuery,
  OrderingDirectionEnum,
  useFleetsDriversLazyQuery,
} from '@electro/fleets/generated/graphql'
import FLEETS_DRIVERS_QUERY from '@electro/fleets/graphql/fleetsDriversQuery.graphql'
import { useDriverWidgets } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/hooks'
import { Autocomplete } from 'libs/shared-ui-components/src/lib/Autocomplete'
import { useDebounce, useMount } from 'react-use'
import { useDriversParams } from '@electro/fleets/src/hooks/stores'
import { useErrorMessage } from '@electro/fleets/src/hooks'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'

interface DriverOption {
  label: string
  pk: number
}

const validationSchema = Yup.object().shape({
  driver: Yup.number().required('drivers.reassign_electrocard.validation.select_driver').nullable(),
})

const DEFAULT_ORDERING = [
  {
    orderBy: DriverTypeOrderingFields.Name,
    direction: OrderingDirectionEnum.Asc,
  },
]

const formatDriverEdgesForSelectMenu = (drivers: FleetsDriversQuery): DriverOption[] =>
  drivers?.fleetsDrivers?.edges
    .map((driverEdge) => {
      if (
        !driverEdge.node.ejnCard ||
        driverEdge.node.ejnCard.status !== EjnEjnTokenStatusChoices.Active
      ) {
        return {
          label: `${driverEdge.node.user.firstName} ${driverEdge.node.user.lastName}`,
          pk: driverEdge.node.pk,
        }
      }
      return null
    })
    .filter((e) => e)
    .sort((a, b) => a.label.localeCompare(b.label))

export const ReassignCardModalScreen = () => {
  const { t } = useTranslation('common')
  const [search, setSearch] = useState('')
  const { driversQueryParams } = useDriversParams()
  const { driver, reassignCard, reassignCardError, reassignCardLoading, closeModalScreen } =
    useDriverWidgets()
  const [getDrivers, { data, loading, error }] = useFleetsDriversLazyQuery()
  const errorMessage = useErrorMessage(error)

  useMount(() => getDrivers({ variables: { first: 50, offset: 0, ordering: DEFAULT_ORDERING } }))

  const formik = useFormik({
    initialValues: {
      driver: '',
    },
    validationSchema,
    onSubmit: (fieldValues) => {
      reassignCard({
        variables: {
          card: driver?.ejnCard?.pk,
          newDriver: parseInt(fieldValues.driver, 10),
          oldDriver: driver?.pk,
        },
        refetchQueries: [
          {
            query: FLEETS_DRIVERS_QUERY,
            variables: driversQueryParams,
          },
        ],
      })
    },
  })

  const driversWithNoElectroCard = useMemo(() => formatDriverEdgesForSelectMenu(data), [data])

  const handleChange = ({ pk }) => formik.setFieldValue('driver', pk)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSearch(event.currentTarget.value)

  useDebounce(
    () => {
      getDrivers({ variables: { first: 50, offset: 0, ordering: DEFAULT_ORDERING, search } })
    },
    200,
    [search],
  )

  const handleClose = () => setSearch('')

  return (
    <>
      <Modal.Header>{t('drivers.reassign_electrocard.heading.reassign_electrocard')}</Modal.Header>
      <Modal.Body data-testid="reassign-card-modal" className="overflow-visible">
        <form id="reassign-card-form" onSubmit={formik.handleSubmit}>
          <Typography variant="p">
            {t('drivers.reassign_electrocard.body.choose_driver')}
          </Typography>
          <Typography variant="p" className="font-medium">
            {t('drivers.reassign_electrocard.body.drivers_list_not_appear')}
          </Typography>

          <div className="mb-8">
            <Autocomplete
              fullWidth
              placeholder={t('drivers.reassign_electrocard.autocomplete.placeholder')}
              loading={loading}
              errorMessage={t(formik.errors.driver) || reassignCardError?.message}
              options={driversWithNoElectroCard}
              noOptionsText={
                search ? (
                  <Trans
                    i18nKey="common:drivers.reassign_electrocard.could_not_find_driver"
                    components={{ searchQuery: <span>{search}</span> }}
                  />
                ) : (
                  t('drivers.reassign_electrocard.no_suggestions_found')
                )
              }
              onChange={handleChange}
              onInputChange={handleInputChange}
              onClose={handleClose}
              getOptionKey={(option: DriverType) => option.pk}
            />

            {!!errorMessage && (
              <Alert variant="error" className="mt-4">
                {errorMessage}
              </Alert>
            )}
          </div>
        </form>
      </Modal.Body>

      <Modal.Actions stacked>
        <Button
          disabled={loading || reassignCardLoading}
          form="reassign-card-form"
          type="submit"
          fullWidth
        >
          {reassignCardLoading
            ? t('drivers.widgets.driver_details.driver_actions.loading.reassigning')
            : t('drivers.widgets.driver_details.driver_actions.reassign_card')}
        </Button>
        <Button
          disabled={reassignCardLoading}
          variant="outline"
          fullWidth
          onClick={closeModalScreen}
        >
          {t('common.button.cancel')}
        </Button>
      </Modal.Actions>
    </>
  )
}
