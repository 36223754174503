import { isDemoMode } from '@electro/fleets/src/utils/envFlagCheck'
import { Button, Modal } from '@electro/shared-ui-components'
import { useState } from 'react'

export const DemoBanner = ({ show }: { show?: boolean }) => {
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)

  return isDemoMode || show ? (
    <>
      <Modal open={detailsModalOpen} onClose={() => setDetailsModalOpen(false)}>
        <Modal.CloseButton />
        <Modal.Header>You are in demo mode</Modal.Header>
        <Modal.Body>
          <p>
            This is a demo version of Electroverse for business. The data you see here is not real
            and is only for demonstration purposes.
          </p>
          <p>
            If you have any questions, please contact us at{' '}
            <a href="mailto:fleets@theelectroverse.com">fleets@theelectroverse.com</a>
          </p>
        </Modal.Body>
        <Modal.Actions>
          <Button className="mx-auto" onClick={() => setDetailsModalOpen(false)}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
      <div className="fixed -bottom-40 -right-36 z-50">
        <button
          onClick={() => setDetailsModalOpen(true)}
          className="bg-action-danger text-white p-28 font-bold -rotate-45 border-2 border-white/40"
        >
          <span className="relative block rotate-45 -top-22 left-3 uppercase text-xl">Demo</span>
        </button>
      </div>
    </>
  ) : null
}
