import { FormikProps, isFunction, useFormik } from 'formik'
import { ReactNode, createContext, useContext, useState } from 'react'
import * as Yup from 'yup'
import { postcodeValidator, ukMobileNumberValidator } from '@electro/shared/utils/validators'
import {
  AdminBusinessBillingFormFieldsEnum,
  AdminBusinessBIllingFormFields,
} from '@electro/fleets/src/components/AdminBusinessBillingForm/AdminBusinessBillingForm.types'
import { useFleetsAddBillingAccount } from '@electro/fleets/src/services'
import { ApolloError } from '@apollo/client'
import FLEETS_BUSINESS_ENTITY_QUERY from '@electro/fleets/graphql/fleetsBusinessEntityQuery.graphql'

interface AdminBusinessBillingReturnType {
  formik: FormikProps<AdminBusinessBIllingFormFields>
  loading: boolean
  error: ApolloError
}

const {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  MOBILE,
  BILLING_ADDRESS_1,
  BILLING_ADDRESS_2,
  BILLING_POSTCODE,
  BILLING_COUNTRY_CODE,
} = AdminBusinessBillingFormFieldsEnum

const adminBusinessBillingSchema = Yup.object().shape({
  [EMAIL]: Yup.string()
    .email('A valid email address is required.')
    .required('Email address is required.'),
  [FIRST_NAME]: Yup.string().required('First name is required.'),
  [LAST_NAME]: Yup.string().required('Last name is required.'),
  [MOBILE]: Yup.string()
    .test('mobile', 'Enter a valid UK mobile phone number.', ukMobileNumberValidator)
    .length(11, 'Enter a valid UK mobile phone number.')
    .required('Mobile number is required.'),
  [BILLING_ADDRESS_1]: Yup.string().required('Billing address line 1 is required.'),
  [BILLING_ADDRESS_2]: Yup.string().required('Billing address line 2 is required.'),
  [BILLING_COUNTRY_CODE]: Yup.string().required('Billing country code is required.'),
  [BILLING_POSTCODE]: Yup.string()
    .required('Postcode is required.')
    .test('postcode', 'This postcode is not valid.', postcodeValidator),
})

const AdminBusinessBillingContext = createContext<AdminBusinessBillingReturnType>(null)

interface Props {
  onSuccess?: () => void
  formFields: Partial<AdminBusinessBIllingFormFields>
}

/**
 * @deprecated This information is now gathered via the Direct Debit setup (Stripe)
 */
const useAdminBusinessBillingProvider = ({
  onSuccess,
  formFields = {},
}: Props): AdminBusinessBillingReturnType => {
  const [addBillingAccountMutation, { loading }] = useFleetsAddBillingAccount()
  const [error, setError] = useState<ApolloError>()

  const submitHandler = async (values: AdminBusinessBIllingFormFields) => {
    try {
      await addBillingAccountMutation({
        refetchQueries: [{ query: FLEETS_BUSINESS_ENTITY_QUERY }],
        variables: values,
      })

      if (isFunction(onSuccess)) onSuccess()
    } catch (err) {
      setError(err)
    }
  }

  const defaultValues: AdminBusinessBIllingFormFields = {
    [EMAIL]: '',
    [FIRST_NAME]: '',
    [LAST_NAME]: '',
    [MOBILE]: '',
    [BILLING_ADDRESS_1]: '',
    [BILLING_ADDRESS_2]: '',
    [BILLING_POSTCODE]: '',
    [BILLING_COUNTRY_CODE]: 'GB',
  }

  const formik: any = useFormik({
    initialValues: { ...defaultValues, ...formFields },
    validationSchema: adminBusinessBillingSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: submitHandler,
  })

  return { formik, loading, error }
}

interface ProviderProps extends Props {
  children: ReactNode | ReactNode[]
}

export const AdminBusinessBillingProvider = ({
  onSuccess,
  formFields,
  children,
}: ProviderProps) => {
  const value = useAdminBusinessBillingProvider({ onSuccess, formFields })

  return (
    <AdminBusinessBillingContext.Provider value={value}>
      {children}
    </AdminBusinessBillingContext.Provider>
  )
}

export const useAdminBusinessBilling = () => {
  const context = useContext(AdminBusinessBillingContext)

  if (!context)
    throw new Error(
      'useAdminBusinessBilling() cannot be used outside of <AdminBusinessBillingProvider/>',
    )

  return context
}
