import { Alert, Card, Typography } from '@electro/shared-ui-components'
import { useFleetsFetchBusinessEntity } from '@electro/fleets/src/services'
import { WidgetSkeleton } from '@electro/fleets/src/skeletons'
import { useMount } from 'react-use'
import useTranslation from 'next-translate/useTranslation'
import { InformationItem } from '@electro/fleets/src/components/BusinessOverview/components/InformationItem'

export const BillingAddress = () => {
  const { t } = useTranslation('common')
  const [fetchBusinessEntity, { data, loading, error }] = useFleetsFetchBusinessEntity()

  useMount(() => fetchBusinessEntity())

  if (loading) return <WidgetSkeleton rows={5} />

  const address1AndAddress2 = data?.fleetsBusinessEntity?.billingAccount?.billingDetails
    ?.billingAddressLine1
    ? `${data?.fleetsBusinessEntity?.billingAccount?.billingDetails?.billingAddressLine1}, ${data?.fleetsBusinessEntity?.billingAccount?.billingDetails?.billingAddressLine2}`
    : null

  return (
    <Card data-testid="billing-address-widget">
      <Typography variant="h3">{t('admin.billing_address.heading')}</Typography>
      {error && (
        <Alert variant="error" className="mt-4">
          <Typography className="mb-2" variant="h3">
            {t('common.error.something_went_wrong')}
          </Typography>

          <Typography>{error?.message}</Typography>
        </Alert>
      )}

      {!!data && !error && (
        <div className="flex flex-col mt-4 gap-5">
          <InformationItem
            labelKey="admin.billing_address.cardholder_first_name"
            value={data?.fleetsBusinessEntity?.billingAccount?.firstName}
          />
          <InformationItem
            labelKey="admin.billing_address.cardholder_last_name"
            value={data?.fleetsBusinessEntity?.billingAccount?.lastName}
          />
          <InformationItem
            labelKey="common.email"
            value={data?.fleetsBusinessEntity?.billingAccount?.email}
          />
          <InformationItem labelKey="common.address" value={address1AndAddress2} />
          <InformationItem
            labelKey="common.postcode"
            value={
              data?.fleetsBusinessEntity?.billingAccount?.billingDetails?.billingAddressPostcode
            }
          />
        </div>
      )}
    </Card>
  )
}
