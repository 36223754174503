import {
  Button,
  IconButton,
  Input,
  SelectMenu,
  Slider,
  Typography,
} from '@electro/shared-ui-components'
import { useFormik } from 'formik'
import {
  CompanyProfileDataFormFieldsEnum,
  CompanyProfileDataFormFields,
  SignUpStep,
  useSignUp,
} from '@electro/fleets/src/components/BusinessOnboardingForm/hooks'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { HavingProblems } from '../HavingProblems'

const businessSectorList = [
  { name: 'Transport' },
  { name: 'Logistics' },
  { name: 'Construction' },
  { name: 'Agriculture' },
  { name: 'Manufacturing' },
  { name: 'Retail' },
  { name: 'Wholesale' },
  { name: 'Healthcare' },
  { name: 'Education' },
  { name: 'Hospitality' },
  { name: 'Finance' },
  { name: 'Real Estate' },
  { name: 'IT' },
  { name: 'Other' },
]

const howDidYouHearAboutUs = [
  { name: 'Trade Show' },
  { name: 'Online Advertising' },
  { name: 'Referral from a Friend' },
  { name: 'Social Media' },
  { name: 'Email Marketing' },
  { name: 'Search Engine (Google)' },
  { name: 'Direct Mail' },
  { name: 'Word of Mouth' },
  { name: 'News Article' },
  { name: 'Local Event' },
  { name: 'Television Ad' },
  { name: 'Radio Ad' },
  { name: 'Podcast Recommendation' },
  { name: 'Other' },
]

const {
  BUSINESS_SECTOR,
  HOW_DID_YOU_HEAR_ABOUT_US,
  NUMBER_OF_ELECTRIC_VEHICLE_DRIVERS,
  PERCENTAGE_CHARGING_AT_HOME,
  PERCENTAGE_CHARGING_AT_WORK,
  PERCENTAGE_CHARGING_ON_PUBLIC_NETWORK,
} = CompanyProfileDataFormFieldsEnum

interface SelectItem {
  name: string
  value: string | number
}

export const BusinessProfileDataForm = () => {
  const [{ formData }, { handleScreenChange, handleSignUpDataChange }] = useSignUp()

  const handleFormSubmit = (values, actions) => {
    handleScreenChange(SignUpStep.USER_DETAILS_FORM)()
    handleSignUpDataChange(values)
    actions.setSubmitting(false)
  }

  const { values, handleSubmit, setFieldValue, errors, handleChange } =
    useFormik<CompanyProfileDataFormFields>({
      initialValues: {
        [BUSINESS_SECTOR]: formData?.[BUSINESS_SECTOR] || '',
        [HOW_DID_YOU_HEAR_ABOUT_US]: formData?.[HOW_DID_YOU_HEAR_ABOUT_US] || '',
        [NUMBER_OF_ELECTRIC_VEHICLE_DRIVERS]: formData?.[NUMBER_OF_ELECTRIC_VEHICLE_DRIVERS] || 0,
        [PERCENTAGE_CHARGING_AT_HOME]: formData?.[PERCENTAGE_CHARGING_AT_HOME] || 0,
        [PERCENTAGE_CHARGING_AT_WORK]: formData?.[PERCENTAGE_CHARGING_AT_WORK] || 0,
        [PERCENTAGE_CHARGING_ON_PUBLIC_NETWORK]:
          formData?.[PERCENTAGE_CHARGING_ON_PUBLIC_NETWORK] || 0,
      },
      onSubmit: handleFormSubmit,
    })

  const handleBusinessSectorChange = (item: SelectItem) => {
    setFieldValue(BUSINESS_SECTOR, item.name)
  }

  const handleHowDidYouHearAboutUsChange = (item: SelectItem) => {
    setFieldValue(HOW_DID_YOU_HEAR_ABOUT_US, item.name)
  }

  return (
    <>
      <IconButton
        className="absolute top-2 left-2 flex items-center"
        onClick={() => {
          handleScreenChange(SignUpStep.BUSINESS_DETAILS_FORM)()
          handleSignUpDataChange(values)
        }}
        aria-label="back to business details form"
      >
        <ArrowLeftIcon className="w-5 h-5 text-secondary" />
      </IconButton>
      <form onSubmit={handleSubmit}>
        <SelectMenu
          label="Sector"
          items={businessSectorList}
          placeholder="e.g. Transport"
          name={BUSINESS_SECTOR}
          onChange={handleBusinessSectorChange}
          errorMessage={errors[BUSINESS_SECTOR] as string}
          initialSelectedItem={businessSectorList.find(
            (item) => item.name === values[BUSINESS_SECTOR],
          )}
        />
        <SelectMenu
          items={howDidYouHearAboutUs}
          label="How did you hear about us?"
          placeholder="e.g. Trade Show"
          name={HOW_DID_YOU_HEAR_ABOUT_US}
          onChange={handleHowDidYouHearAboutUsChange}
          errorMessage={errors[HOW_DID_YOU_HEAR_ABOUT_US] as string}
          initialSelectedItem={howDidYouHearAboutUs.find(
            (item) => item.name === values[HOW_DID_YOU_HEAR_ABOUT_US],
          )}
        />

        <Input
          fullWidth
          label="How many Electric Vehicles do you have?"
          type="number"
          name={NUMBER_OF_ELECTRIC_VEHICLE_DRIVERS}
          onChange={handleChange}
          placeholder="e.g. 5"
          errorMessage={errors?.[NUMBER_OF_ELECTRIC_VEHICLE_DRIVERS]}
        />

        <Typography className="mt-10">Roughly, where do your EVs complete charging?</Typography>

        <Slider
          min={0}
          max={100}
          onChange={(sliderValues) => setFieldValue(PERCENTAGE_CHARGING_AT_WORK, sliderValues[0])}
          initialValues={[values[PERCENTAGE_CHARGING_AT_WORK]]}
          name={PERCENTAGE_CHARGING_AT_WORK}
        >
          {({ values: sliderValues }) => (
            <div className="mt-6">
              <Slider.Label>
                <div className="flex justify-between">
                  <Typography variant="small">Workplace charging</Typography>
                  <Typography variant="small">{sliderValues[0]}%</Typography>
                </div>
              </Slider.Label>
              <Slider.Rail />
            </div>
          )}
        </Slider>

        <Slider
          min={0}
          max={100}
          onChange={(sliderValues) => setFieldValue(PERCENTAGE_CHARGING_AT_HOME, sliderValues[0])}
          initialValues={[values[PERCENTAGE_CHARGING_AT_HOME]]}
          name={PERCENTAGE_CHARGING_AT_HOME}
        >
          {({ values: sliderValues }) => (
            <div className="mt-10">
              <Slider.Label>
                <div className="flex justify-between">
                  <Typography variant="small">Home charging</Typography>
                  <Typography variant="small">{sliderValues[0]}%</Typography>
                </div>
              </Slider.Label>
              <Slider.Rail />
            </div>
          )}
        </Slider>

        <Slider
          min={0}
          max={100}
          onChange={(sliderValues) =>
            setFieldValue(PERCENTAGE_CHARGING_ON_PUBLIC_NETWORK, sliderValues[0])
          }
          initialValues={[values[PERCENTAGE_CHARGING_ON_PUBLIC_NETWORK]]}
          name={PERCENTAGE_CHARGING_ON_PUBLIC_NETWORK}
        >
          {({ values: sliderValues }) => (
            <div className="mt-10">
              <Slider.Label>
                <div className="flex justify-between">
                  <Typography variant="small">Public charging</Typography>
                  <Typography variant="small">{sliderValues[0]}%</Typography>
                </div>
              </Slider.Label>
              <Slider.Rail />
            </div>
          )}
        </Slider>
        <Button className="mt-8" type="submit" fullWidth>
          Continue
        </Button>

        <HavingProblems />
      </form>
    </>
  )
}
