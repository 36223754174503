import { Alert, Button, Card, Skeleton, Typography } from '@electro/shared-ui-components'
import { useFleetsBusinessEntityQuery } from '@electro/fleets/generated/graphql'
import { isObject } from '@electro/shared/utils/typed'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

const styles = {
  card: {
    root: 'flex-1 flex lg:flex-row flex-col items-center',
    info: 'flex-[2] flex justify-between items-center',
    action: 'lg:flex-[1.5] flex justify-end mt-3',
  },
  message: {
    lightGray: 'text-tertiary-shade',
    error: 'text-action-danger border-action-danger',
  },
}

export const PaymentMethod = () => {
  const router = useRouter()
  const { data, loading, error } = useFleetsBusinessEntityQuery()

  const navigateToAddPaymentMethod = () =>
    router.push('/dashboard/payment-and-receipts/add-payment')

  const cardText = data?.fleetsBusinessEntity.billingAccount?.paymentMethod
    ? 'Replace card'
    : 'Add card'

  const paymentMethod = useMemo(
    () => data?.fleetsBusinessEntity?.billingAccount?.paymentMethod?.paymentMethodDetails,
    [data?.fleetsBusinessEntity?.billingAccount?.paymentMethod?.paymentMethodDetails],
  )
  const expiryMonth = useMemo(() => {
    if (isObject(paymentMethod) && 'expiryMonth' in paymentMethod) {
      return paymentMethod.expiryMonth
    }

    return null
  }, [paymentMethod])
  const expiryYear = useMemo(() => {
    if (isObject(paymentMethod) && 'expiryYear' in paymentMethod) {
      return paymentMethod.expiryYear
    }

    return null
  }, [paymentMethod])

  return (
    <Card>
      <Alert variant="info" className="mb-8">
        <p className="mb-2 text-sm font-medium text-base-dark/50">Good to know</p>

        <Typography className="text-sm lg:text-white lg:text-base">
          Access to the platform is free in Beta. For any public charging you do through
          Electroverse, we will take payment on Mondays.
        </Typography>
      </Alert>

      {loading && (
        <div className={styles.card.root}>
          <div className={styles.card.info}>
            <div>
              <Skeleton variant="text" width={100} />
            </div>

            <div>
              <Skeleton variant="text" width={150} />
            </div>

            <div>
              <Skeleton variant="text" width={50} />
            </div>
          </div>

          <div className={styles.card.action}>
            <Skeleton variant="circular" width={190} height={40} />
          </div>
        </div>
      )}

      {!loading && error && (
        <div className={styles.card.root}>
          <div className={styles.card.info}>
            <div className={styles.message.error}>
              Could not fetch payment method! Try again later!
            </div>
          </div>

          <div className={styles.card.action}>
            <Button className="px-2">{cardText}</Button>
          </div>
        </div>
      )}

      {!loading && !error && !data?.fleetsBusinessEntity.billingAccount?.paymentMethod && (
        <div className={styles.card.root}>
          <div className={styles.card.info}>
            <div className={styles.message.lightGray}>
              It looks like you haven’t added a payment method yet.
            </div>
          </div>

          <div className={styles.card.action}>
            <Button onClick={navigateToAddPaymentMethod}>{cardText}</Button>
          </div>
        </div>
      )}

      {!loading && !error && data?.fleetsBusinessEntity.billingAccount?.paymentMethod && (
        <div className={styles.card.root}>
          <div className={styles.card.info}>
            <Typography>
              {data.fleetsBusinessEntity.billingAccount.firstName} &nbsp;
              {data.fleetsBusinessEntity.billingAccount.lastName}
            </Typography>

            <Typography>
              **** **** **** &nbsp;
              {
                data.fleetsBusinessEntity.billingAccount.paymentMethod.paymentMethodDetails
                  ?.identifier
              }
            </Typography>

            <Typography>
              {expiryMonth}/{expiryYear}
            </Typography>
          </div>

          <div className={styles.card.action}>
            <Button onClick={navigateToAddPaymentMethod}>{cardText}</Button>
          </div>
        </div>
      )}
    </Card>
  )
}
