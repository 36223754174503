import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react'
import { ModalScreensEnum } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/DriverWidgets.types'
import {
  DriverType,
  FleetsAssignCardToDriverMutation,
  FleetsAssignCardToDriverMutationVariables,
  FleetsAssignVehicleMutation,
  FleetsAssignVehicleMutationVariables,
  FleetsDeactivateDriversCardMutation,
  FleetsDeactivateDriversCardMutationVariables,
  FleetsReassignCardToDriverMutation,
  FleetsReassignCardToDriverMutationVariables,
  useFleetsAssignCardToDriverMutation,
  useFleetsAssignVehicleMutation,
  useFleetsDeactivateDriversCardMutation,
} from '@electro/fleets/generated/graphql'
import { ApolloError, MutationFunction } from '@apollo/client'
import { useFleetsReassignCardToDriver } from '@electro/fleets/src/services'
import * as Sentry from '@sentry/nextjs'
import FLEETS_VEHICLE_QUERY from '@electro/fleets/graphql/fleetsVehicles.graphql'
import FLEETS_DRIVERS_QUERY from '@electro/fleets/graphql/fleetsDriversQuery.graphql'
import { useLocalStorage } from 'react-use'
import { SHOW_DRIVER_ASSIGNED_TO_VEHICLE_SUCCESS_MODAL } from '@electro/fleets/src/constants/localStorage'
import { useDriversParams } from '@electro/fleets/src/hooks/stores'
import { clearFleetsDriversFromCache } from '@electro/fleets/src/utils/apolloUtils'

interface DriverWidgetsProviderProps {
  children: ReactNode
  driver: DriverType
}

interface UseDriverWidgetsContextReturnType {
  driver: DriverType
  activeModalScreen: ModalScreensEnum
  setActiveModalScreen: Dispatch<SetStateAction<ModalScreensEnum>>
  closeModalScreen: () => void
  deactivateCard: MutationFunction<
    FleetsDeactivateDriversCardMutation,
    FleetsDeactivateDriversCardMutationVariables
  >
  deactivateCardLoading: boolean
  deactivateCardError: ApolloError
  assignCard: MutationFunction<
    FleetsAssignCardToDriverMutation,
    FleetsAssignCardToDriverMutationVariables
  >
  assignCardLoading: boolean
  assignCardError: ApolloError
  reassignCard: MutationFunction<
    FleetsReassignCardToDriverMutation,
    FleetsReassignCardToDriverMutationVariables
  >
  reassignCardLoading: boolean
  reassignCardError: ApolloError
  assignVehicle: MutationFunction<FleetsAssignVehicleMutation, FleetsAssignVehicleMutationVariables>
  assignVehicleLoading: boolean
  assignVehicleError: ApolloError
  handleStopShowingVehicleAssignedSuccessModal: () => void
  handleShowVehicleAssignedSuccessModal: () => void
}
interface UseDriverWidgetsProviderProps {
  driver: DriverType
}

const useDriverWidgetsProvider = ({
  driver,
}: UseDriverWidgetsProviderProps): UseDriverWidgetsContextReturnType => {
  const [activeModalScreen, setActiveModalScreen] = useState(null)
  const { driversQueryParams } = useDriversParams()

  const [
    isAssignVehicleToDriverSuccessModalVisible,
    setIsAssignVehicleToDriverSuccessModalVisible,
  ] = useLocalStorage(SHOW_DRIVER_ASSIGNED_TO_VEHICLE_SUCCESS_MODAL, true)

  const handleStopShowingVehicleAssignedSuccessModal = () => {
    setIsAssignVehicleToDriverSuccessModalVisible(!isAssignVehicleToDriverSuccessModalVisible)
  }

  const handleShowVehicleAssignedSuccessModal = () => {
    if (isAssignVehicleToDriverSuccessModalVisible) {
      setActiveModalScreen(ModalScreensEnum.ASSIGN_VEHICLE_SUCCESS_MODAL_SCREEN)
    } else {
      closeModalScreen()
    }
  }

  const closeModalScreen = useCallback(() => {
    setActiveModalScreen(null)
  }, [])

  const [deactivateCard, { loading: deactivateCardLoading, error: deactivateCardError }] =
    useFleetsDeactivateDriversCardMutation({
      refetchQueries: [
        {
          query: FLEETS_DRIVERS_QUERY,
          variables: driversQueryParams,
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        closeModalScreen()
      },
      update: (cache, { data }) => {
        if (data.fleetsFreezeDriversCard.success) {
          clearFleetsDriversFromCache(cache)
        }
      },
      onError(error) {
        console.error('Deactivate card failed: ', error.message)
      },
    })

  const [assignCard, { loading: assignCardLoading, error: assignCardError }] =
    useFleetsAssignCardToDriverMutation({
      refetchQueries: [
        {
          query: FLEETS_DRIVERS_QUERY,
          variables: driversQueryParams,
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        closeModalScreen()
      },
      update: (cache, { data }) => {
        if (data.fleetsAssignCardToDriver.success) {
          clearFleetsDriversFromCache(cache)
        }
      },
      onError(error) {
        console.error('Assign card failed: ', error.message)
      },
    })

  const [reassignCard, { loading: reassignCardLoading, error: reassignCardError }] =
    useFleetsReassignCardToDriver({
      refetchQueries: [
        {
          query: FLEETS_DRIVERS_QUERY,
          variables: driversQueryParams,
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        closeModalScreen()
      },
      update: (cache, { data }) => {
        if (data.fleetsReassignCardToDriver.success) {
          clearFleetsDriversFromCache(cache)
        }
      },
      onError(error) {
        console.error('Reassign card failed: ', error.message)
      },
    })

  const [assignVehicle, { loading: assignVehicleLoading, error: assignVehicleError }] =
    useFleetsAssignVehicleMutation({
      refetchQueries: [
        {
          query: FLEETS_VEHICLE_QUERY,
          variables: { driver: driver.pk, first: 10, offset: 0 },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => handleShowVehicleAssignedSuccessModal(),
      onError(err) {
        Sentry.captureException(err)
      },
    })

  return {
    driver,
    activeModalScreen,
    setActiveModalScreen,
    closeModalScreen,
    deactivateCard,
    deactivateCardLoading,
    deactivateCardError,
    assignCard,
    assignCardLoading,
    assignCardError,
    reassignCard,
    reassignCardLoading,
    reassignCardError,
    assignVehicle,
    assignVehicleLoading,
    assignVehicleError,
    handleShowVehicleAssignedSuccessModal,
    handleStopShowingVehicleAssignedSuccessModal,
  }
}

const DriverWidgetsContext = createContext<UseDriverWidgetsContextReturnType>(null)

const useDriverWidgets = () => {
  const context = useContext(DriverWidgetsContext)
  if (!context) {
    throw new Error(
      `useDriverWidgets() cannot be used outside the context of <DriverWidgetsProvider/> `,
    )
  }
  return context
}

const DriverWidgetsProvider = ({ children, driver }: DriverWidgetsProviderProps) => {
  const context = useDriverWidgetsProvider({ driver })
  return <DriverWidgetsContext.Provider value={context}>{children}</DriverWidgetsContext.Provider>
}

export { DriverWidgetsProvider, useDriverWidgets }
